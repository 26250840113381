<template>
    <div class="menCenter">
        <img class="bg" src="@/assets/image/longbg.png" alt="" />
        <div class="line"></div>
        <header class="h">MENBERSHIP CENTER</header>
        <ul class="content">
            <li class="item" v-for="(item,i) in list" :key='i' @click="jumpToList(item.id)">
                <img :src="baseImageUrl+item.imageUrl" />
                <span>{{item.name}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import {fetchMemberCenterList, baseImageUrl} from "../const/utils.js";
export default {
    data () {
        return {
            baseImageUrl,
            list: [],
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.list = await fetchMemberCenterList(3);
        },
        jumpToList(id) {
            this.$router.push({
                path: '/memberCenterList',
                query: {
                    id
                } 
            })
        },
    }
}
</script>
<style lang="stylus" scoped>
.menCenter {
    padding-bottom: 60px;
    .bg {
        width: 100%;
    }
    .line {
        width 100%;
        height: 6px;
        opacity: 0.83;
        margin-top: 6px;
        background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
    }
    .h {
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #909090;
        letter-spacing: 2.03px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 40px;
        background: url('~@/assets/image/detailRectBg.png') no-repeat center;
    }
    .content {
        .item {
            width: 1200px;
            height: 60px;
            margin: 10px auto 0;
            background: #FFFFFF;
            box-shadow: 0 0 8px 4px rgba(81,99,128,0.10);
            border-radius: 4px;
            display: flex;
            align-items center;
            img {
                width: 44px;
                height: 44px;
                margin-left: 30px;

            }
            span {
                margin-left: 26px;
                font-family: PingFangSC-Regular;
                font-size: 20px;
                color: #333333;
                letter-spacing: 1.93px;
            }
        }
    }
}
</style>