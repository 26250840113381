<template>
  <div class="login">
    <div class="input-form">
      <img src="../assets/image/logo.png" alt="" class="logo" />
      <el-form
        :model="ruleForm"
        :show-message="false"
        :rules="rules"
        ref="ruleForm"
        class="ruleSa"
      >
        <div class="label-title">用户名</div>
        <el-form-item
          label
          name="用户名"
          prop="username"
          style="position: relative; margin-bottom: 10px"
        >
          <el-input
            type="text"
            placeholder="请输入您的用户名"
            v-model="ruleForm.username"
          >
            <i
              slot="prefix"
              class="iconfont icon-yonghuming"
              style="font-size: 17px; margin-left: 3px"
            ></i>
          </el-input>
          <span v-if="nameFlag" class="error-message">{{ nameMess }}</span>
        </el-form-item>
        <div class="label-title">密码</div>
        <div v-if="isNoLook" class="pwd-img">
          <el-form-item
            label
            name="密码"
            prop="password"
            style="position: relative; margin-bottom: 6px"
          >
            <el-input
              type="password"
              placeholder="请输入您的密码"
              v-model="ruleForm.password"
            >
            </el-input>
            <span v-if="pswFlag" class="error-message">{{ pswMess }}</span>
          </el-form-item>
          <img
            src="../assets/image/nolook.png"
            alt=""
            class="look-no"
            @click="isNoLook = !isNoLook"
          />
        </div>
        <div v-if="!isNoLook" class="pwd-img">
          <el-form-item
            label
            name="密码"
            prop="password"
            style="position: relative; margin-bottom: 6px"
          >
            <el-input placeholder="请输入您的密码" v-model="ruleForm.password">
            </el-input>
            <span v-if="pswFlag" class="error-message">{{ pswMess }}</span>
          </el-form-item>
          <img
            src="../assets/image/look.png"
            alt=""
            class="look-no"
            @click="isNoLook = !isNoLook"
          />
        </div>
        <el-form-item style="margin-bottom: 0; margin-top: 50px">
          <el-button
            style="width: 100%"
            :loading="loading"
            type="primary"
            @click="submitForm()"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="foot-btn">
        <div class="go-back" @click="$router.go(-1)">
          <i class="iconfont icon-fanhui5"></i>
          返回
        </div>

        <div class="to-register" @click="goRegister">
          立即注册 <img src="../assets/image/register.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import baseUrl from '../const/const';
import { setCookie, getCookie } from '../const/utils';
export default {
  data () {

    let check = (rule, value, callback) => {
      if (value === "") {
        if (rule.field == "username") {
          this.nameFlag = true;
          this.nameMess = "请输入账号";
        } else {
          this.pswFlag = true;
          this.pswMess = "请输入密码";
        }
        return callback(new Error());
      } else {
        if (rule.field == "username") {
          this.nameFlag = false;
          this.nameMess = "";
        } else {
          this.pswFlag = false;
          this.pswMess = "";
        }
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: ""
      },
      // src: ,
      message: "",
      nameMess: "",
      nameFlag: false,
      isRemember: '',//记住密码
      pswMess: "",
      pswFlag: false,
      loading: false,
      checked: true,
      rules: {
        username: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ]
      },
      isNoLook: true,
    }
  },
  async mounted () {
    document.onkeydown = event => {
      //回车登录
      if (event.keyCode === 13) {
        this.submitForm();
      }
    };
  },
  methods: {
    goRegister () {
      this.$router.push({
        path: '/register'
      })
    },
    submitForm () {
      this.loading = true;
      let timeNumber = new Date().getTime();
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var fd = new FormData()
          let that = this
          Object.keys(that.ruleForm).forEach(function (key) {
            fd.append(key, that.ruleForm[key])
          });
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          axios.post(`${baseUrl}/front/user/login`, fd, config) // 园区基地都是4
            .then(({ data }) => {
              let type = ''
              if (data.success) {
                type = 'success'
                this.$router.push({
                  path: '/'
                })
                let expireDays = 3
                let curTime = new Date().getTime()
                localStorage.setItem('token', data.data)
                localStorage.setItem('loginTime', curTime)
                let config1 = {
                  headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "token": localStorage.getItem('token')
                  }
                }
                axios.post(`${baseUrl}/front/user/getUserInfo`, {}, config1)
                  .then(({ data }) => {
                    if (data.data.type == 1) {
                      localStorage.setItem('userinfoname', data.data.name)
                    } else {
                      localStorage.setItem('userinfoname', data.data.companyName)
                    }
                    this.$store.state.userinfoname = localStorage.getItem('userinfoname')
                    this.$store.state.isUserName = true
                  })
              } else {
                type = 'error'
              }
              this.$message({
                message: data.mesg,
                type: type
              })
              this.loading = false
            })
        }
      });
    },
  }
}
</script>


<style lang="stylus" >
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url('../assets/image/signInbg.png') center center no-repeat;
  // overflow: scroll;
  background-size: cover;

  // position: fixed;
  .input-form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 600px;
    height: 650px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 4px rgba(81, 99, 128, 0.5);
    border-radius: 6px;
    margin: 200px auto 200px;

    .logo {
      width: 100px;
      height: 100px;
      margin-top: 40px;
      margin-bottom: 30px;
    }

    .el-form {
      width: 500px;

      .label-title {
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        margin-bottom: 10px;
        margin-left: 32px;
      }

      .pwd-img {
        position: relative;

        .look-no {
          position: absolute;
          top: 14.5px;
          right: 15px;
          width: 40px;
          height: 29px;
        }
      }

      .el-form-item {
        width: 100%;
        margin-bottom: 40px !important;

        .el-input {
          position: relative;
          width: 100%;
          height: 58px;

          .el-input__inner {
            height: 58px;
            line-height: 58px;
            font-size: 16px;
            padding-left: 35px;
          }
        }

        .error-message {
          position: absolute;
          top: 0;
          right: 60px;
          color: red;
          font-size: 16px;
          line-height: 58px;
        }

        .el-button {
          width: 320px;
          height: 58px;
          font-family: PingFangSC-Regular;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          background: #516380;
          border-radius: 4px;
          margin-top: 20px;
        }

        .el-button--primary {
          background: #516380;
        }
      }
    }

    .foot-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      letter-spacing: 0;
      text-align: center;
      cursor: pointer;

      .go-back {
        color: #dcdfe6;
        color: #dcdfe6;
        cursor: pointer;

        .icon-fanhui5 {
          font-size: 24px;
        }
      }

      .to-register {
        width: 500px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #FE4757;

        img {
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>