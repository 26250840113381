<template>
  <div>
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="slide" v-for="(s, i) in swpierList" :key="i">
          <a target="_blank" :href="`${s.jumpLinkUrl}`">
            <img :src="baseImageUrl + s.imageUrl" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="line"></div>
    <div class="content">
      <div class="leftImgstyle">
        <header class="header">基地</header>
        <div>
          <img :src="baseImageUrl + base.imageUrl" alt="" />
          <div class="textWrap">
            <h4>基地</h4>
            <div class="desc">{{ base.description }}</div>
            <div class="btn" @click="jumpToBase">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content grey">
      <div class="rightImgstyle">
        <header class="header">园区</header>
        <div>
          <img :src="baseImageUrl + park.imageUrl" alt="" />
          <div class="textWrap">
            <h4>园区</h4>
            <div class="desc">{{ park.description }}</div>
            <div class="btn" @click="jumpToPark">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftImgstyle">
        <header class="header">专业服务</header>
        <div>
          <img :src="baseImageUrl + service.imageUrl" alt="" />
          <div class="textWrap">
            <h4>专业服务</h4>
            <div class="desc">{{ service.description }}</div>
            <div class="btn" @click="jumpToService">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" id="blue">
      <div class="rightImgstyle">
        <header class="header">会员中心</header>
        <div>
          <img :src="baseImageUrl + member.imageUrl" alt="" />
          <div class="textWrap">
            <h4>会员中心</h4>
            <div class="desc">{{ member.description }}</div>
            <div class="btn" @click="jumpToMemberCenter">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 最新资讯 -->
    <div class="content newMsg">
      <header class="header bg2">最新资讯</header>
      <div class="picInfo" v-if="infoList.length">
        <div class="item" v-for="(item, i) in infoList" :key="i">
          <img :src="baseImageUrl + infoList[i].imageUrl" />
          <span
            ><em>{{ infoList[i].essayTitle }}</em></span
          >
        </div>
      </div>
      <div class="knowMore" @click="jumpToKnowMore">了解更多行业</div>
    </div>
    <div class="content qiye">
      <header class="header bg2">合作企业</header>
      <div class="yellowbg"></div>
      <div class="bottomSwiperwrap">
        <swiper class="swiper2" ref="mySwiper2" :options="swiperOpt2">
          <swiper-slide class="slide2" v-for="(p, i) in partnerList" :key="i">
            <a target="_blank" :href="`${p.jumpLinkUrl}`">
              <img :src="baseImageUrl + p.logoUrl" />
              <span>{{ p.name }}</span>
            </a>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { slideshowList, baseImageUrl, pcIndexText, pcIndexInformation, pcIndexPartner } from "../const/utils";
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'


export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      baseImageUrl,
      swpierList: [],
      infoList: [],
      swiperOptions: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 3000,
        }
      },
      swiperOpt2: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 20,
        // nextButton: "swiper-button-next",
        // prevtButton: "swiper-button-prev",
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          disabledClass: 'disabled',
        },
        // pagination: {
        //     el: '.swiper-pagination',
        //     clickable: true,
        // },
        // autoplay: {
        //     delay: 3000,
        // }
      },
      partnerList: [],
      base: {},
      park: {},
      service: {},
      member: {},
    }
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      this.swpierList = await slideshowList();
      const d = await pcIndexText();
      this.base = d.find(({ title }) => title === "基地");
      this.park = d.find(({ title }) => title === "园区");
      this.service = d.find(({ title }) => title === "服务");
      this.member = d.find(({ title }) => title === "会员中心");
      const templ = await pcIndexInformation(); // 最新资讯内容
      this.infoList = templ.filter(({ imageUrl }) => imageUrl);
      this.partnerList = await pcIndexPartner(); // 合作企业轮播
    },
    // jump(url) {
    //     location.href = `http://${url}`;
    // },
    jumpToPark () {
      this.$router.push({
        path: '/park'
      })
    },
    jumpToBase () {
      this.$router.push({
        path: '/base'
      })
    },
    jumpToKnowMore () {
      this.$router.push({
        path: '/infoList'
      })
    },
    jumpToMemberCenter () {
      this.$router.push({
        path: '/memberCenter'
      })
    },
    jumpToService () {
      this.$router.push({
        path: '/service'
      })
    },
  },

}
</script>

<style>
.swiper-pagination-bullet {
  width: 55px;
  height: 6px;
  opacity: 0.5;
  background: #ffffff;
  border-radius: 5px;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fdcb6f;
}
</style>
<style lang="stylus" scoped>
.slide {
  /*height: 460px;*/
  width: 100%;

  img {
    /*height: 460px;*/
    width: 100%;
  }
}

.line {
  width: 100%;
  height: 6px;
  opacity: 0.83;
  margin-top: 6px;
  background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
}

.header {
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #516380;
  letter-spacing: 12px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 8px;
  background: url('~@/assets/image/indexRectBg.png') no-repeat center bottom;

  &.bg2 {
    background: url('~@/assets/image/detailRectBg.png') no-repeat center;
  }
}

.content {
  background: #fff;

  .leftImgstyle, .rightImgstyle {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    img, .textWrap {
      float: left;
    }

    .textWrap {
      padding: 69px;
      width: 500px;
      box-sizing: border-box;

      h4 {
        font-family: PingFangSC-Regular;
        font-size: 28px;
        color: #333333;
        margin-bottom: 20px;
      }

      .desc {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #666666;
        letter-spacing: 0;
        margin-bottom: 36px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
      }

      .btn {
        cursor: pointer;
        background: #07365E;
        box-shadow: 0 0 8px 4px rgba(81, 99, 128, 0.1);
        border-radius: 8px;
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0;
      }
    }

    img {
      width: 700px;
      height: 400px;
    }
  }

  .rightImgstyle {
    img, .textWrap {
      float: right;
    }
  }

  &.grey {
    background: #F2F2F2;
  }

  &#blue {
    background: #516380;

    header, h4 {
      color: #FFFFFF;
    }

    .desc {
      color: #DDDDDD;
    }

    .btn {
      background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
      box-shadow: 0 0 8px 4px rgba(81, 99, 128, 0.1);
      border-radius: 8px;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #333333;
      cursor: pointer;
    }
  }
}

.newMsg {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 106px;

  .picInfo {
    overflow: hidden;
    width: 1210px;

    .item {
      float: left;
      height: 219px;
      margin: 0 10px 10px 0;
      position: relative;

      &:nth-of-type(1) {
        width: 450px;
      }

      &:nth-of-type(2) {
        width: 380px;
      }

      &:nth-of-type(3) {
        width: 350px;
      }

      &:nth-of-type(4) {
        width: 350px;
      }

      &:nth-of-type(5) {
        width: 380px;
      }

      &:nth-of-type(6) {
        width: 450px;
      }

      img {
        height: 219px;
        width: 100%;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        bottom: 0;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

        em {
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
  }

  .knowMore {
    width: 280px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 0;
    background: #07365E;
    box-shadow: 0 0 8px 4px rgba(81, 99, 128, 0.1);
    border-radius: 8px;
    margin: 40px auto 0;
  }
}

.qiye {
  position: relative;

  .yellowbg {
    width: 100%;
    height: 230px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-image: linear-gradient(90deg, #EDD8A6 0%, #CBA450 98%);
  }

  .bottomSwiperwrap {
    width: 1020px;
    margin: 50px auto 116px;
    position: relative;
    z-index: 99;

    .swiper2 {
      width: 1020px;
    }

    .slide2 {
      height: 400px;
      width: 240px;

      img {
        width: 240px;
        height: 340px;
      }

      span {
        display: block;
        text-align: center;
        width: 240px;
        height: 60px;
        line-height: 60px;
        background: #F2F2F2;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #516380;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .swiper-button-prev {
    left: -50px;
    top: 70%;
    outline: none;
    background-image: url('~@/assets/image/l.png');
  }

  .swiper-button-next {
    right: -50px;
    top: 70%;
    outline: none;
    background-image: url('~@/assets/image/r.png');
  }

  .disabled {
    opacity: 0.5;
  }
}
</style>