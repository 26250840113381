<template>
  <div class="service">
    <img class="bg" src="@/assets/image/longbg.png" alt="" />
    <div class="line"></div>
    <header class="h">SERVICE</header>
    <div class="content">
      <div class="service-pic"></div>
      <ul class="service-list">
        <li
          :class="[item.ischecked ? 'active' : '']"
          @click="checkPage(item)"
          v-for="(item, i) in titleList"
          :key="i"
        >
          {{ item.name }}
        </li>
      </ul>
      <div style="margin-top: 40px; font-size: 28px">
        {{ detailinfo && detailinfo.title }}
      </div>
      <p style="margin: 30px 0" v-html="detailinfo && detailinfo.detail"></p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import baseUrl from '../const/const';
export default {
  data () {
    return {
      titleList: [],
      detailinfo: {}
    }
  },
  mounted () {
    this.classifyPage()
  },
  methods: {
    classifyPage () {
      axios.post(`${baseUrl}/front/classify/page`, { effectModule: 5 }) // 园区基地都是4
        .then(({ data }) => {
          if (data.code === "S00000") {
            let arr = data.data.records
            this.titleList = arr
            this.titleList.forEach((item, index) => {
              if (index == 0) {
                this.$set(item, 'ischecked', true)
              } else {
                this.$set(item, 'ischecked', false)
              }
            })
            this.frontEasyPage(this.titleList[0].id)
          }
        })
    },
    frontEasyPage (id) {
      axios.post(`${baseUrl}/front/essay/page`, { classifyId: id }) // 园区基地都是4
        .then(({ data }) => {
          console.log(data);
          if (data.code === "S00000") {
            this.detailinfo = data.data.records[0]
          }
        })
    },
    checkPage (item) {
      this.titleList.forEach(ite => {
        if (ite.id == item.id) {
          ite.ischecked = true
        } else {
          ite.ischecked = false
        }
      })
      this.frontEasyPage(item.id)
    }
  },
}
</script>
<style lang="stylus" scoped>
.service {
  .h {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #909090;
    letter-spacing: 2.03px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    background: url('~@/assets/image/detailRectBg.png') no-repeat center;
  }

  .bg {
    width: 100%;
  }

  .line {
    width: 100%;
    height: 6px;
    opacity: 0.83;
    margin-top: 6px;
    background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
  }

  .content {
    width: 1100px;
    margin: 0 auto;

    // .service-pic{
    // width: 100%;
    // height: 28px;
    // background: red;
    // margin: 40px 0 25px;
    // }
    .service-list {
      display: flex;
      justify-content: space-between;

      li {
        width: 120px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        color: #333;
        line-height: 42px;
        border: 1px solid #999999;

        &.active {
          color: #fff;
          background: #07365E;
          border: none;
        }
      }
    }
  }
}
</style>