<template>
  <div class="parkBase">
    <img class="bg" src="@/assets/image/longbg.png" alt="" />
    <div class="line"></div>
    <div class="listContent">
      <div
        class="item"
        v-for="(a, i) in list"
        :key="i"
        @click="jumpToDetail(a.id)"
      >
        <img :src="baseImageUrl + a.imageUrl" alt="" />
        <div>
          <header>{{ a.title }}</header>
          <div class="text">{{ a.briefIntroduction }}</div>
          <div class="time">{{ a.createTime }}</div>
        </div>
      </div>
      <div v-if="!list.length" class="nothingHolder">暂时没有数据</div>
    </div>
  </div>
</template>

<script>

// import axios from 'axios';
// import baseUrl from '../const/const';
import {
  findIdByName,
  essayPage,
  baseImageUrl
} from '../const/utils.js';
const nameMap = {
  base: '基地',
  park: '园区',
}
export default {
  data () {
    return {
      baseImageUrl,
      list: [],
      fromCode: '',
    }
  },
  watch: {
    $route () {
      this.fetchData();
    }
  },
  async mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      const queryName = this.$route.name;
      if (queryName === 'infoList') { // 首页资讯信息来的
        const id = await findIdByName(1, '资讯信息'); // 传首页 1, 找 资讯信息
        this.list = await essayPage({ classifyId: id });
        this.fromCode = 4;
      } else if (queryName === 'memberCenterList') { // 会员中心来的
        // const {effectModule,name} = this.$route.query;
        // const id = await findIdByName(effectModule,name);
        // console.log(`real id: ${id}`);
        const { id } = this.$route.query;
        this.list = await essayPage({ classifyId: id });
        this.fromCode = 3;
        // this.list = await essayPage({classifyId: this.$route.query.id});
      } else {
        // 园区或基地
        const id = await findIdByName(4, nameMap[queryName]); // 园区基地都是4
        this.list = await essayPage({ classifyId: id });
        this.fromCode = queryName == 'park' ? 1 : 2 // 1: '园区', 2: '基地',
      }
    },
    jumpToDetail (id) {
      this.$router.push({
        path: '/detail',
        query: {
          id,
          fromCode: this.fromCode
        },

      })
    }
  }
}
</script>


<style lang="stylus" scoped>
.parkBase {
  padding-bottom: 60px;

  .bg {
    width: 100%;
  }

  .line {
    width: 100%;
    height: 6px;
    opacity: 0.83;
    margin-top: 6px;
    background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
  }

  .listContent {
    width: 1200px;
    margin: 0 auto;

    .item {
      padding: 60px 60px 30px;
      overflow: hidden;
      border-bottom: 1px solid #979797;
      cursor: pointer;

      img {
        width: 280px;
        height: 160px;
        flex-grow: 1;
        margin-right: 30px;
        float: left;
        border-radius: 10px;
      }

      &>div {
        width: 760px;
        float: left;

        header {
          font-family: PingFangSC-Medium;
          font-size: 28px;
          color: #333333;
          margin-bottom: 10px;
        }

        .text {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #666666;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        .time {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }

  .nothingHolder {
    width: 700px;
    padding: 300px;
    font-family: PingFangSC-Medium;
    text-align: center;
    font-size: 28px;
    color: #ccc;
    margin: 0 auto;
  }
}
</style>