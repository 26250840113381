<template>
  <div class="member-service global-list-style">
    <img class="bg" src="@/assets/image/longbg.png" alt="" />
    <div class="line"></div>
    <div class="meau">
      首页<em>&gt;</em>会员服务<em>&gt;</em>{{ curTabName }}
    </div>
    <div class="content" v-if="!needFormShow">
      <div class="tab-list">
        会员服务：
        <div
          :class="[index == curIndex ? 'item item-active' : 'item']"
          v-for="(item, index) in tabList"
          :key="index"
          @click="clickTab(index, item)"
        >
          {{ item.name }}
          <span class="el-icon-success" v-if="index == curIndex" />
        </div>
      </div>

      <div class="listContent">
        <div
          class="item"
          v-for="(a, i) in list"
          :key="i"
          @click="jumpToDetail(a.id)"
        >
          <img :src="baseImageUrl + a.imageUrl" alt="" />
          <div>
            <header>{{ a.title }}</header>
            <div class="text">{{ a.briefIntroduction }}</div>
            <div class="time">{{ a.createTime }}</div>
          </div>
        </div>
        <div v-if="!list.length" class="nothingHolder">暂时没有数据</div>
      </div>
    </div>
    <div class="content-form" v-if="needFormShow">
      <div class="form-title">
        <img src="../assets/image/icon_list_tit.png" alt="" />
        会员需求提交
      </div>
      <div class="form-box">
        <el-form
          label-position="left"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="标题:" prop="title">
            <el-input
              v-model="ruleForm.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="需求类型:" prop="category">
            <el-select v-model="ruleForm.category" placeholder="请选择需求类型">
              <el-option
                :label="item.name"
                :value="item.type"
                v-for="(item, index) in categoryLiat"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="需求内容:" prop="content">
            <el-input
              type="textarea"
              v-model="ruleForm.content"
              placeholder="请输入需求内容"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn">
              <el-button class="reseve" @click="submitForm('ruleForm')"
                >保存</el-button
              >
              <el-button class="cancle" @click="cancleBtn('ruleForm')"
                >取消</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import baseUrl from '../const/const';
import { setCookie, getCookie } from '../const/utils';
import {
  findIdByName,
  essayPage,
  baseImageUrl
} from '../const/utils.js';
export default {
  data () {
    return {
      baseImageUrl,
      list: [],
      fromCode: 6,
      tabList: [
        {
          name: '会员企业推广',
          type: 9
        }, {
          name: '会员业务咨询',
          type: 10
        }, {
          name: '会员需求提交',
          type: 0
        },
      ],
      curTabType: 9,
      curTabName: '会员企业推广',
      curIndex: 0,
      needFormShow: false,
      ruleForm: {
        title: '',
        category: '',
        content: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '请选择需求类型', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入需求内容', trigger: 'blur' }
        ]
      },
      categoryLiat: [
        {
          name: '商业需求',
          type: 1
        }, {
          name: '生活需求',
          type: 2

        }
      ]
    }
  },
  // watch: {
  //   $route () {
  //     this.fetchData();
  //   }
  // },
  async mounted () {
    this.fetchData(this.curTabType);
  },
  methods: {
    cancleBtn (formName) {
      this.needFormShow = false
      this.curIndex = 0
      this.curTabName = '会员企业推广'
      this.$refs[formName].resetFields();
      this.fetchData(this.curTabType)
    },
    clearForm () {
      this.ruleForm = {
        title: '',
        category: '',
        content: ''
      }
    },
    submitForm (formName) {
      localStorage.setItem('ruleForm', JSON.stringify(this.ruleForm))
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "token": localStorage.getItem('token')
            }
          }
          axios.post(`${baseUrl}/front/userRequirements/add`, this.ruleForm, config)
            .then(({ data }) => {
              let type = ''
              if (data.success) {
                type = 'success'
                this.needFormShow = false
                this.curIndex = 0
                this.clearForm()
                this.fetchData(this.curTabType)
                localStorage.removeItem('ruleForm')
              } else {
                type = 'error'
              }
              this.$message({
                message: data.mesg,
                type: type
              })
              this.loading = false
            })
          this.loading = false
        } else {
          this.loading = false
          return false;
        }
      });
    },
    clickTab (index, item) {
      this.curIndex = index
      this.curTabName = item.name
      if (item.name == '会员需求提交') {
        if (localStorage.getItem('token')) {
          if (localStorage.getItem('ruleForm')) {
            this.ruleForm = JSON.parse(localStorage.getItem('ruleForm'))
          }
          this.needFormShow = true
        } else {
          this.$message.error('未登录')
          this.$router.push({
            path: '/login'
          })
        }
      } else {
        this.list = []
        this.needFormShow = false
        this.fetchData(item.type)
      }
    },
    async fetchData (name) {
      let config = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      axios.post(`${baseUrl}/front/essay/page`, { effectModule: name }, config)
        .then(({ data }) => {
          if (data.code === "S00000") {
            let arr = data.data.records
            this.list = arr
          }
        })
    },
    jumpToDetail (id) {
      this.$router.push({
        path: '/detail',
        query: {
          id,
          fromCode: this.fromCode
        },

      })
    }
  }
}
</script>


<style lang="stylus" >
.member-service {
  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    display: none;
  }

  .content-form {
    width: 1200px;
    height: 900px;
    margin: 0 auto;
    padding: 40px 36px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    .form-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: PingFangSC-Semibold;
      font-size: 20px;
      color: #3F454B;

      img {
        width: 20px;
        height: 20px;
        padding-right: 5px;
      }
    }

    .form-box {
      .el-form {
        .el-form-item {
          width: 100%;
          margin-top: 30px !important;

          .el-form-item__label {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0;
          }

          .el-input {
            position: relative;
            width: 100%;
            height: 36px;

            .el-input__inner {
              height: 36px;
              line-height: 36px;
              font-size: 14px;
            }
          }

          .el-select {
            width: 100%;
          }

          .el-textarea {
            .el-textarea__inner {
              height: 500px !important;
            }

            &::-webkit-input-placeholder {
              line-height: 36px;
              font-size: 14px;
            }
          }

          .error-message {
            position: absolute;
            top: 0;
            right: 60px;
            color: red;
            font-size: 16px;
            line-height: 36px;
          }

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
          }

          .el-button {
            width: 210px;
            height: 50px;
            font-family: PingFangSC-Regular;
            font-size: 22px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            border-radius: 2px;

            &.reseve {
              background: #D9A85B;
            }

            &.cancle {
              background: #DDDDDD;
              margin-left: 80px;
            }
          }
        }
      }
    }
  }
}
</style>