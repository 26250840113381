<template>
    <div class="brief">
        <img class="bg" src="@/assets/image/longbg.png"/>
        <div class="line"></div>
        <div class="navga">
            <span>首页<em>&gt;</em>峰会简介</span>
            <div v-if='data.applyFunctionFlag == 1' class="signInBtn" @click='jumpToSignIn'>报名</div>
        </div>
        <header class="h">INTRODUCE</header>
        <div class="content" v-html='data.detail' v-if='data.detail'></div>
        <div v-else class="nothingHolder">暂时没有内容</div>
    </div>
</template>

<script>
import { getBrief, findIdByName, essayPage } from "../const/utils";
export default {
    data() {
        return {
            data: {
                detail: ''
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.data = await getBrief();
        },
        jumpToSignIn() {
            // console.log("this.data", this.data);
            this.$router.push({
                path: '/signIn',
                query: {
                    essayId: this.data.id,
                }
            })
        }
    },
}
</script>

<style lang="stylus">
    .brief {
        padding-bottom: 60px;
        .bg {
            width: 100%;
        }
        .navga {
            width: 1100px;
            margin: 25px auto;
            display: flex;
            align-items: center;
            span {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #8E8E93;
                em {
                    margin: 0 2px;
                }
            }
            .signInBtn {
                width: 160px;
                height: 50px;
                background: #516380;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                font-family: PingFangSC-Regular;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
        .line {
            width 100%;
            height: 6px;
            opacity: 0.83;
            margin-top: 6px;
            background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
        }
        .h {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #909090;
            letter-spacing: 2.03px;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 40px;
            background: url('~@/assets/image/detailRectBg.png') no-repeat center;
        }
        .content {
            width: 1100px;
            margin: 50px auto 80px;
        }
    }

    .nothingHolder {
        width: 700px;
        padding: 300px;
        font-family: PingFangSC-Medium;
        text-align center;
        font-size: 28px;
        color: #ccc;
        margin: 0 auto;
    }
</style>