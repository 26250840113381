<template>
  <div class="activity global-list-style">
    <img class="bg" src="@/assets/image/longbg.png" alt="" />
    <div class="line"></div>
    <div class="meau">首页<em>&gt;</em>活动<em>&gt;</em>{{ curTabName }}</div>
    <div class="content">
      <div class="tab-list">
        会员服务：
        <div
          :class="[index == curIndex ? 'item item-active' : 'item']"
          v-for="(item, index) in tabList"
          :key="index"
          @click="clickTab(index, item)"
        >
          {{ item.name }}
          <span class="el-icon-success" v-if="index == curIndex" />
        </div>
      </div>

      <div class="listContent">
        <div
          class="item"
          v-for="(a, i) in list"
          :key="i"
          @click="jumpToDetail(a.id)"
        >
          <img :src="baseImageUrl + a.imageUrl" alt="" />
          <div>
            <header>{{ a.title }}</header>
            <div class="text">{{ a.briefIntroduction }}</div>
            <div class="time">{{ a.activityTime }}</div>
          </div>
        </div>
        <div v-if="!list.length" class="nothingHolder">暂时没有数据</div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import baseUrl from '../const/const';
import {
  findIdByName,
  essayPage,
  baseImageUrl
} from '../const/utils.js';
export default {
  data () {
    return {
      baseImageUrl,
      list: [],
      fromCode: 5,
      tabList: [
        {
          name: '峰会活动',
          type: 7
        }, {
          name: '小组活动',
          type: 8
        },
      ],
      curTabType: 7,
      curTabName: "峰会活动",
      curIndex: 0,
    }
  },
  // watch: {
  //   $route () {
  //     this.fetchData();
  //   }
  // },
  async mounted () {
    this.fetchData(this.curTabType);
  },
  methods: {
    clickTab (index, item) {
      this.curIndex = index
      this.curTabName = item.name
      this.list = []
      this.fetchData(item.type)
    },
    async fetchData (name) {
      let config = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
      axios.post(`${baseUrl}/front/essay/page`, { effectModule: name }, config) // 园区基地都是4
        .then(({ data }) => {
          if (data.code === "S00000") {
            let arr = data.data.records
            this.list = arr
          }
        })
    },
    jumpToDetail (id) {
      this.$router.push({
        path: '/detail',
        query: {
          id,
          fromCode: this.fromCode
        },

      })
    }
  }
}
</script>


<style lang="stylus" >
.activity {
}
</style>