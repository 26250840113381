<template>
  <div class="people-reg">
    <el-form
      :model="ruleForm"
      :show-message="false"
      :rules="rules"
      ref="ruleForm"
      class="ruleSa"
    >
      <div class="label-title">用户名</div>
      <el-form-item
        label
        name="用户名"
        prop="account"
        style="position: relative; margin-bottom: 10px"
      >
        <el-input
          type="text"
          placeholder="请输入您的用户名"
          v-model="ruleForm.account"
        >
          <i
            slot="prefix"
            class="iconfont icon-yonghuming"
            style="font-size: 17px; margin-left: 3px"
          ></i>
        </el-input>
        <span v-if="accountFlag" class="error-message">{{ accountMess }}</span>
      </el-form-item>
      <div class="label-title">姓名</div>
      <el-form-item
        name="姓名"
        prop="name"
        style="position: relative; margin-bottom: 10px"
      >
        <el-input
          type="text"
          placeholder="请输入您的姓名"
          v-model="ruleForm.name"
        >
          <i
            slot="prefix"
            class="iconfont icon-yonghuming"
            style="font-size: 17px; margin-left: 3px"
          ></i>
        </el-input>
        <span v-if="nameFlag" class="error-message">{{ nameMess }}</span>
      </el-form-item>
      <div class="label-title">手机号码</div>
      <el-form-item
        name="手机号码"
        prop="phone"
        style="position: relative; margin-bottom: 10px"
      >
        <el-input
          type="text"
          placeholder="请输入您的手机号码"
          v-model="ruleForm.phone"
        >
          <i
            slot="prefix"
            class="iconfont icon-yonghuming"
            style="font-size: 17px; margin-left: 3px"
          ></i>
        </el-input>
        <span v-if="phoneFlag" class="error-message">{{ phoneMess }}</span>
      </el-form-item>
      <div class="label-title">上传营业执照</div>
      <div class="photo-wrapper">
        <el-form-item label v-if="!idCardFrontUrl" prop="idCardFrontUrl">
          <el-upload
            class="upload-demo"
            drag
            :auto-upload="false"
            :show-file-list="false"
            :multiple="true"
            action
            accept="image/*"
            :on-change="httpRequestUpload1"
            :limit="1"
            v-model="ruleForm.idCardFrontUrl"
          >
            <i class="el-icon-plus"></i>
            <div class="tip">身份证正面</div>
          </el-upload>
          <span v-if="idcardFUrlFlag" class="error-message">{{
            idcardFUrlMess
          }}</span>
        </el-form-item>
        <div class="upload-img" @click="idCardFrontUrl = ''" v-else>
          <div class="img-content">
            <i class="el-icon-error"></i>
          </div>
          <img
            :src="`${baseUrl}/front/file/getImage?path=${idCardFrontUrl}`"
            alt=""
            class="img"
          />
        </div>
        <el-form-item label v-if="!idCardBackUrl" prop="idCardBackUrl">
          <el-upload
            class="upload-demo"
            drag
            :auto-upload="false"
            :show-file-list="false"
            :multiple="true"
            action
            accept="image/*"
            :on-change="httpRequestUpload2"
            :limit="1"
            v-model="ruleForm.idCardBackUrl"
          >
            <i class="el-icon-plus"></i>
            <div class="tip">身份证反面</div>
          </el-upload>
          <span v-if="idcardBUrlFlag" class="error-message">{{
            idcardBUrlMess
          }}</span>
        </el-form-item>
        <div class="upload-img" @click="idCardBackUrl = ''" v-else>
          <div class="img-content">
            <i class="el-icon-error"></i>
          </div>
          <img
            :src="`${baseUrl}/front/file/getImage?path=${idCardBackUrl}`"
            alt=""
            class="img"
          />
        </div>
      </div>
      <div class="label-title">设置密码</div>
      <div v-if="isNoLook" class="pwd-img">
        <el-form-item
          label
          name="设置密码"
          prop="password"
          style="position: relative; margin-bottom: 6px"
        >
          <el-input
            type="password"
            placeholder="请输入您的密码"
            v-model="ruleForm.password"
            :prefix-icon="isNoLook ? 'el-icon-lock' : 'el-icon-unlock'"
          >
          </el-input>
          <span v-if="pwdFlag" class="error-message">{{ pwdMess }}</span>
        </el-form-item>
        <img
          src="../assets/image/nolook.png"
          alt=""
          class="look-no"
          @click="isNoLook = !isNoLook"
        />
      </div>
      <div v-if="!isNoLook" class="pwd-img">
        <el-form-item
          label
          name="设置密码"
          prop="password"
          style="position: relative; margin-bottom: 6px"
        >
          <el-input
            placeholder="请输入您的密码"
            v-model="ruleForm.password"
            :prefix-icon="isNoLook ? 'el-icon-lock' : 'el-icon-unlock'"
          >
          </el-input>
          <span v-if="pwdFlag" class="error-message">{{ pwdMess }}</span>
        </el-form-item>
        <img
          src="../assets/image/look.png"
          alt=""
          class="look-no"
          @click="isNoLook = !isNoLook"
        />
      </div>
      <div class="label-title">确认密码</div>
      <div v-if="isNoLook1" class="pwd-img">
        <el-form-item
          label
          name="确认密码"
          prop="againPwd"
          style="position: relative; margin-bottom: 6px"
        >
          <el-input
            type="password"
            placeholder="请再次输入您的密码"
            v-model="ruleForm.againPwd"
            :prefix-icon="isNoLook1 ? 'el-icon-lock' : 'el-icon-unlock'"
          >
          </el-input>
          <span v-if="agpwdFlag" class="error-message">{{ agpwdMess }}</span>
        </el-form-item>
        <img
          src="../assets/image/nolook.png"
          alt=""
          class="look-no"
          @click="isNoLook1 = !isNoLook1"
        />
      </div>
      <div v-if="!isNoLook1" class="pwd-img">
        <el-form-item
          label
          name="确认密码"
          prop="againPwd"
          style="position: relative; margin-bottom: 6px"
        >
          <el-input
            placeholder="请再次输入您的密码"
            v-model="ruleForm.againPwd"
            :prefix-icon="isNoLook1 ? 'el-icon-lock' : 'el-icon-unlock'"
          >
          </el-input>
          <span v-if="agpwdFlag" class="error-message">{{ agpwdMess }}</span>
        </el-form-item>
        <img
          src="../assets/image/look.png"
          alt=""
          class="look-no"
          @click="isNoLook1 = !isNoLook1"
        />
      </div>
      <el-form-item style="margin-bottom: 0; margin-top: 50px">
        <div class="btn">
          <el-button
            style="width: 100%"
            :loading="loading"
            type="primary"
            @click="submitForm()"
            >注册</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';
import baseUrl from '../const/const';
export default {
  data () {
    let check = (rule, value, callback) => {
      console.log(rule)
      console.log(value)
      if (value === "") {
        if (rule.field == "account") {
          this.accountFlag = true;
          this.accountMess = "请输入您的用户名";
        } else if (rule.field == "name") {
          this.nameFlag = true;
          this.nameMess = "请输入您的姓名";
        } else if (rule.field == "phone") {
          this.phoneFlag = true;
          this.phoneMess = "请输入您的手机号码";
        } else if (rule.field == "password") {
          this.pwdFlag = true;
          this.pwdMess = "请输入密码";
        } else if (rule.field == "againPwd") {
          this.agpwdFlag = true;
          this.agpwdMess = "请再次输入您的密码";
        } else if (rule.field == "idCardFrontUrl") {
          this.idcardFUrlFlag = true;
          this.idcardFUrlMess = "请上传身份证正面";
        } else if (rule.field == "idCardBackUrl") {
          this.idcardBUrlFlag = true;
          this.idcardBUrlMess = "请上传身份证反面";
        }
        return callback(new Error());
      } else {
        if (rule.field == "account") {
          this.accountFlag = false;
          this.accountMess = "";
        } else if (rule.field == "name") {
          this.nameFlag = false;
          this.nameMess = "";
        } else if (rule.field == "phone") {
          var strTel = /^1[23456789]\d{9}$/
          if (!strTel.test(this.ruleForm.phone)) {
            this.phoneFlag = true;
            this.phoneMess = "请输入11位手机号码";
            return callback(new Error());
          } else {
            this.phoneFlag = false;
            this.phoneMess = "";
          }
        } else if (rule.field == "password") {
          this.pwdFlag = false;
          this.pwdMess = "";
        } else if (rule.field == "againPwd") {
          if (this.ruleForm.password != this.ruleForm.againPwd) {
            this.agpwdFlag = true;
            this.agpwdMess = "密码与确认密码不一致，请重新输入";
            return callback(new Error());
          } else {
            this.agpwdFlag = false;
            this.agpwdMess = "";
          }
        } else if (rule.field == "idCardFrontUrl") {
          this.idcardFUrlFlag = false;
          this.idcardFUrlMess = "";
        } else if (rule.field == "idCardBackUrl") {
          this.idcardBUrlFlag = false;
          this.idcardBUrlMess = "";
        }
        callback();
      }
    };
    return {
      baseUrl: baseUrl,
      accountFlag: false,
      nameFlag: false,
      phoneFlag: false,
      idcardFUrlFlag: false,
      idcardBUrlFlag: false,
      pwdFlag: false,
      agpwdFlag: false,
      isNoLook: true,
      isNoLook1: true,
      loading: false,
      ruleForm: {
        type: 1,
        account: "",
        name: '',
        phone: '',
        password: "",
        againPwd: '',
        idCardFrontUrl: '',
        idCardBackUrl: ''
      },
      idCardFrontUrl: '',
      idCardBackUrl: '',
      rules: {
        account: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ],
        idCardFrontUrl: [
          {
            required: true,
            trigger: "change",
            validator: check
          }
        ],
        idCardBackUrl: [
          {
            required: true,
            trigger: "change",
            validator: check
          }
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ],
        againPwd: [
          {
            required: true,
            trigger: "blur",
            validator: check
          }
        ]
      },
    }
  },
  async mounted () {
  },
  methods: {
    // 上传身份证正面
    httpRequestUpload1 (file) {
      var fd = new FormData()
      fd.append('file', file.raw)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(`${baseUrl}/front/file/uploadImage`, fd, config) // 园区基地都是4
        .then(({ data }) => {
          let type = ''
          if (data.success) {
            type = 'success'
            this.idCardFrontUrl = data.data
          } else {
            type = 'error'
          }
          this.$message({
            message: data.mesg,
            type: type
          })
          this.loading = false
        })
    },
    // 上传身份证反面
    httpRequestUpload2 (file) {
      var fd = new FormData()
      fd.append('file', file.raw)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(`${baseUrl}/front/file/uploadImage`, fd, config) // 园区基地都是4
        .then(({ data }) => {
          let type = ''
          if (data.success) {
            type = 'success'
            this.idCardBackUrl = data.data
          } else {
            type = 'error'
          }
          this.$message({
            message: data.mesg,
            type: type
          })
          this.loading = false
        })
    },
    submitForm () {
      this.loading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.password != this.ruleForm.againPwd) {
            this.agpwdFlag = true;
            this.agpwdMess = "密码与确认密码不一致，请重新输入";
          } else {
            this.agpwdFlag = false;
            this.agpwdMess = "";
            this.ruleForm.idCardFrontUrl = `${baseUrl}/front/file/getImage?path=${this.idCardFrontUrl}`
            this.ruleForm.idCardBackUrl = `${baseUrl}/front/file/getImage?path=${this.idCardBackUrl}`
            let config = {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              }
            }
            axios.post(`${baseUrl}/front/user/register`, this.ruleForm, config) // 园区基地都是4
              .then(({ data }) => {
                let type = ''
                if (data.success) {
                  type = 'success'
                  this.$router.push({
                    path: '/login'
                  })
                } else {
                  type = 'error'
                }
                this.$message({
                  message: data.mesg,
                  type: type
                })
                this.loading = false
              })

          }
          this.loading = false
        } else {
          this.loading = false
        }
      });
    },
  }
}
</script>

<style lang="stylus">
.people-reg {
  .el-input {
    font-size: 30px !important;

    .el-input__prefix, .el-input__suffix {
      top: 4px;
    }

    .el-input__icon {
      .el-icon-lock, el-icon-unlock {
        line-height: 48px;
      }
    }
  }

  .photo-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .upload-img {
      width: 47% !important;
      position: relative;
      background-color: #f5f5f5 !important;
      border: 2px dashed #099CD5 !important;
      border-radius: 0;
      height: 165px;

      .img-content {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2);
        cursor: pointer;

        .el-icon-error {
          font-size: 30px;
          color: red;
        }
      }

      .img {
        width: 100%;
        height: 165px;
      }
    }

    .el-form-item {
      width: 47% !important;
      height: 165px;
      margin-bottom: 0 !important;

      .upload-demo {
        width: 100%;

        .el-upload {
          width: 100%;

          .el-upload-dragger {
            width: 100%;
            height: 165px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #f5f5f5 !important;
            border: 2px dashed #099CD5 !important;
            border-radius: 0;

            .el-icon-plus {
              font-size: 50px;
              color: #AAAAAA;
            }

            .tip {
              font-size: 16px;
              color: #AAAAAA;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

.people-reg {
  padding: 10px 20px;

  .el-form {
    width: 100%;

    .label-title {
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      margin-bottom: 10px;
      margin-left: 32px;
    }

    .pwd-img {
      position: relative;

      .look-no {
        position: absolute;
        top: 9.5px;
        right: 15px;
        width: 40px;
        height: 29px;
      }
    }

    .el-form-item {
      width: 100%;
      margin-bottom: 10px;

      .el-input {
        position: relative;
        width: 100%;
        height: 48px;

        .el-input__inner {
          height: 48px;
          line-height: 48px;
          font-size: 16px;
          padding-left: 35px;
        }
      }

      .error-message {
        position: absolute;
        top: 0;
        right: 60px;
        color: red;
        font-size: 16px;
        line-height: 48px;
      }

      .btn {
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .el-button {
          width: 500px !important;
          height: 48px;
          font-family: PingFangSC-Regular;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          background: #516380;
          border-radius: 4px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>