<template>
  <div class="detailWrap">
    <img class="bg" src="@/assets/image/longbg.png" />
    <div class="line"></div>
    <div class="navga">
      <div class="menu">
        首页&gt;{{ fromText }}&gt;<span class="title-cap">{{
          data.title
        }}</span>
      </div>
      <div
        v-if="data.applyFunctionFlag == 1"
        class="signInBtn"
        @click="jumpToSignIn"
      >
        报名
      </div>
    </div>

    <div class="content" v-if="data.detail">
      <div class="head">{{ data.title }}</div>
      <div class="image" v-html="data.detail"></div>
    </div>
    <div v-else class="nothingHolder">暂时没有内容</div>
  </div>
</template>

<script>

import {
  getDetail
} from '../const/utils.js';
import { setCookie, getCookie } from '../const/utils';

const FROMMAP = {
  1: '园区',
  2: '基地',
  3: '会员中心',
  4: '最新资讯', // 首页 最新资讯来的
  5: '活动', // 首页 最新资讯来的
  6: '会员服务', // 首页 最新资讯来的
}
export default {
  data () {
    return {
      data: {
        detail: '',
      },
      fromText: '',
    }
  },
  // watch: {
  //     $route() {
  //         this.fetchData();
  //     }
  // },
  async mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      const { id, fromCode } = this.$route.query;
      this.data = await getDetail(id);
      this.fromText = FROMMAP[fromCode];
    },
    jumpToSignIn () {
      if (localStorage.getItem('token')) {
        const { id } = this.$route.query;
        this.$router.push({
          path: '/signIn',
          query: {
            essayId: id,
          }
        })
      } else {
        this.$message.error('未登录')
        this.$router.push({
          path: '/login'
        })
      }
    },
  }
}
</script>


<style lang="stylus" scoped>
.detailWrap {
  padding-bottom: 60px;
  background: #f2f2f2;

  .bg {
    width: 100%;
  }

  .navga {
    width: 1100px;
    margin: 25px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .menu {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #8E8E93;

      em {
        margin: 0 2px;
      }

      .title-cap {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .signInBtn {
      width: 160px;
      height: 50px;
      background: #516380;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  .line {
    width: 100%;
    height: 6px;
    opacity: 0.83;
    margin-top: 6px;
    background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
  }

  .content {
    width: 1100px;
    margin: 0px auto 60px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 50px 40px;
    box-sizing: border-box;

    .head {
      text-align: center;
      font-family: PingFangSC-Medium;
      font-size: 28px;
      color: #333333;
      margin-bottom: 20px;
    }

    .image >>> img {
      max-width: 100%;
      height auto;
      max-height auto;
    }

  }
}

.nothingHolder {
  width: 700px;
  padding: 300px;
  font-family: PingFangSC-Medium;
  text-align: center;
  font-size: 28px;
  color: #ccc;
  margin: 0 auto;
}
</style>