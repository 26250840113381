<template>
  <div class="register">
    <div class="reg-content">
      <div class="tab-btn-wrapper">
        <div class="tab">
          <div
            :class="index == curIndex ? 'item item-active' : 'item'"
            v-for="(item, index) in tabList"
            :key="index"
            @click="clickTab(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="go-back" @click="$router.go(-1)">
          返回
          <i class="iconfont icon-fanhui6"></i>
        </div>
      </div>
      <div class="form-box">
        <cmpy-reg v-if="curIndex == 0"></cmpy-reg>
        <people-reg v-if="curIndex == 1"></people-reg>
      </div>
    </div>
  </div>
</template>

<script>
import cmpyReg from '../components/cmpyReg'
import peopleReg from '../components/peopleReg'
import {
  findIdByName,
  essayPage,
  baseImageUrl
} from '../const/utils.js';
export default {
  data () {
    return {
      tabList: ['企业注册', '个人注册'],
      curIndex: 0
    }
  },
  components: {
    cmpyReg,
    peopleReg
  },
  async mounted () {
  },
  methods: {
    clickTab (index) {
      this.curIndex = index
    }
  }
}
</script>


<style lang="stylus" >
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url('../assets/image/signInbg.png') center center no-repeat;
  // overflow: hidden;
  background-size: cover;

  // position: fixed;
  .reg-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    height: 900px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 4px rgba(81, 99, 128, 0.5);
    border-radius: 6px;
    padding: 20px 50px;
    box-sizing: border-box;
    margin: 50px;

    .tab-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Semibold;
      font-size: 20px;
      color: #DCE0E5;

      .tab {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .item {
          border-right: 1px solid #D9A85B;
          padding: 5px 20px;
          margin-top: 15px;
          cursor: pointer;

          &:last-child {
            border: none;
          }

          &.item-active {
            color: #516380;
          }
        }
      }

      .go-back {
        color: #dcdfe6;
        cursor: pointer;

        .icon-fanhui6 {
          font-size: 24px;
        }
      }
    }

    .form-box {
    }
  }
}
</style>