<template>
  <div class="signIn">
    <div class="wrapper">
      <div class="content" @click="hideSelector">
        <div class="title">基本信息</div>
        <ul class="list">
          <li class="item require">
            <div class="twrap">
              <span class="t">1.姓名</span>
              <span class="err" v-if="nameError">请输入姓名</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.name"
                type="text"
                placeholder="请输入你的姓名"
              />
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">2.手机号码</span>
              <span class="err" v-if="phoneError">请输入正确的手机号码</span>
            </div>
            <div class="vWrap">
              <input
                id="a"
                maxlength="11"
                class="v"
                v-model="form.phone"
                type="text"
                placeholder="请输入您的手机号码"
              />
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">3.证件类型</span>
              <span class="err" v-if="zhenjianError">请选择证件类型</span>
            </div>
            <div class="vWrap">
              <div class="v" @click.stop="showSelectType = true">
                {{ typeText }}
              </div>
              <label for="b">选择</label>
              <div v-if="showSelectType" class="selector">
                <div
                  :class="{ sel: form.zhenjian == 1 }"
                  @click="selectType(1, '身份证')"
                >
                  身份证
                </div>
                <div
                  :class="{ sel: form.zhenjian == 2 }"
                  @click="selectType(2, '护照')"
                >
                  护照
                </div>
              </div>
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">4.证件号码</span>
              <span class="err" v-if="zhenjianhaomaError">请输入证件号码</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.zhenjianhaoma"
                type="text"
                placeholder="请输入您的证件号码"
              />
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">5.性别</span>
              <span class="err" v-if="xinbieError">请选择性别</span>
            </div>
            <div class="vWrap">
              <!-- <input id="c" class="v" v-model="form.xinbie" type='text' placeholder="请选择性别" /> -->
              <div
                class="v"
                :class="{ default: form.xinbie != 1 && form.xinbie != 2 }"
                @click.stop="showSelectGender = true"
              >
                {{ genderText }}
              </div>
              <label for="b">选择</label>
              <div v-if="showSelectGender" class="selector">
                <div
                  :class="{ sel: form.xinbie == 1 }"
                  @click="selectGender(1, '男')"
                >
                  男
                </div>
                <div
                  :class="{ sel: form.xinbie == 2 }"
                  @click="selectGender(2, '女')"
                >
                  女
                </div>
              </div>
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">6.出生日期</span>
              <span class="err" v-if="chushenriqiError">请输入出生日期</span>
            </div>
            <div class="vWrap">
              <!-- <input class="v" v-model="form.chushenriqi" type='text' placeholder="请输入" /> -->
              <date-picker
                hide-icon
                class="v"
                placeholder="请输入"
                v-model="date"
                type="date"
                format="yyyy-MM-dd"
              />
            </div>
          </li>
          <li class="item">
            <div class="twrap">
              <span class="t">7.居住地址</span>
            </div>
            <div class="vWrap">
              <input
                id="d"
                class="v"
                v-model="form.juzhudizhi"
                type="text"
                placeholder="请输入居住地址"
              />
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">8.楼栋门牌号（或详细住址）</span>
              <span class="err" v-if="loudongError">请输入详细住址</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.loudong"
                type="text"
                placeholder="请输入如小区、单元、楼栋号、门牌号"
              />
            </div>
          </li>
          <li class="item">
            <div class="twrap">
              <span class="t">9.单位名称</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.danweimincheng"
                type="text"
                placeholder="请输入您的单位（学校）名称"
              />
            </div>
          </li>
          <li class="item">
            <div class="twrap">
              <span class="t">10.统一社会信用代码</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.xinyongdaima"
                type="text"
                placeholder="请填写统一社会信用代码"
              />
            </div>
          </li>
          <li class="item require">
            <div class="twrap">
              <span class="t">11.目前所在城市</span>
              <span class="err" v-if="chengshiError">请输入城市</span>
            </div>
            <div class="vWrap">
              <input
                class="v"
                v-model="form.chengshi"
                type="text"
                placeholder="请输入您目前所在城市"
              />
            </div>
          </li>
        </ul>
        <div class="submitBtn" @click="submitFn">点击提交</div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import DatePicker from '@hyjiacan/vue-datepicker'
import '@hyjiacan/vue-datepicker/dist/datepicker.css'
import { essayApplySubmit } from "../const/utils";
export default {
  components: { DatePicker },
  data () {
    return {
      date: '',
      showSelectType: false, // 证件类型选择
      showSelectGender: false, // 性别选择
      typeText: '身份证', // 证件类型文字
      genderText: '请选择性别', // 性别文字
      form: {
        name: '',
        phone: '',
        zhenjian: 1,
        zhenjianhaoma: '',
        xinbie: 0,
        juzhudizhi: '',
        loudong: '',
        danweimincheng: '',
        xinyongdaima: '',
        chengshi: '',
      },
      nameError: false,
      phoneError: false,
      zhenjianError: false,
      zhenjianhaomaError: false,
      xinbieError: false,
      chushenriqiError: false,
      loudongError: false,
      chengshiError: false,
    }
  },
  methods: {
    async submitFn () {
      if (!this.checkFormDataError()) {
        const {
          name,
          phone,
          zhenjian,
          zhenjianhaoma,
          xinbie,
          juzhudizhi,
          loudong,
          danweimincheng,
          xinyongdaima,
          chengshi,
        } = this.form;
        const { essayId } = this.$route.query;
        let config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "token": localStorage.getItem('token')
          }
        }
        const isSuccess = await essayApplySubmit({
          name: name.trim(),
          phone: phone.trim(),
          address: juzhudizhi.trim(),
          sex: xinbie,
          birthday: this.date,
          companyName: danweimincheng.trim(),
          detailAddress: loudong.trim(),
          socialCreditCode: xinyongdaima.trim(),
          presentCity: chengshi.trim(),
          idCardType: this.typeText, // 类型传中文
          idCard: zhenjianhaoma,
          essayId
        }, config);
        if (isSuccess) {
          alert("报名成功 !");
          this.$router.back();
        } else {
          // alert("报名失败 ! 请稍后再试");
        }
      };
    },
    selectType (val, text) {
      this.form.zhenjian = val;
      this.showSelectType = false;
      this.typeText = text;
    },
    selectGender (val, text) {
      this.form.xinbie = val;
      this.showSelectGender = false;
      this.genderText = text;
    },
    hideSelector () {
      this.showSelectType = false;
      this.showSelectGender = false;
    },
    checkFormDataError () {
      console.log(this.form);
      this.nameError = !this.form.name.trim().length;
      this.phoneError = !/^\d{11}$/.test(this.form.phone);
      this.zhenjianError = this.form.zhenjian === 0;
      this.zhenjianhaomaError = !this.form.zhenjianhaoma.length;
      this.xinbieError = this.form.xinbie === 0;
      this.chushenriqiError = !this.date;
      this.loudongError = !this.form.loudong;
      this.chengshiError = !this.form.chengshi;
      // console.log(this.nameError , this.phoneError , this.zhenjianError
      //     , this.zhenjianhaomaError , this.xinbieError 
      //     , this.chushenriqiError , this.loudongError 
      //     , this.chengshiError);
      return this.nameError || this.phoneError || this.zhenjianError
        || this.zhenjianhaomaError || this.xinbieError
        || this.chushenriqiError || this.loudongError
        || this.chengshiError;

    },
  }
}
</script>
<style  lang="stylus">
.date-picker--popper-reference {
  display: block;
}

.date-picker input {
  color: #000 !important;
}

.date-picker input {
  width: 498px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 4px;
  outline: none;
  text-indent: 30px;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #999999;
  line-height: 44px;
  text-align: left;

  &::-webkit-input-placeholder {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #999999;
    line-height: 44px;
  }
}
</style>
<style lang="stylus" scoped>
.signIn {
  width: 100%;

  .line {
    width: 100%;
    height: 6px;
    margin-top: 3px;
    opacity: 0.83;
    background-image: linear-gradient(-90deg, #EDD8A6 0%, #CBA450 98%);
  }

  .wrapper {
    width: 100%;
    height: 1080px;
    background: url('~@/assets/image/signInbg.png') no-repeat center;
    background-size: cover;
  }

  .content {
    width: 1200px;
    height: 1080px;
    margin: 0 auto;
    background: rgba(255, 255, 255 0.8);
    padding-top: 60px;
    box-sizing: border-box;

    .title {
      background: url('~@/assets/image/signInTitle.png') no-repeat;
      background-size: cover;
      box-sizing: border-box;
      width: 245px;
      height: 66px;
      margin: 0 auto;
      padding-left: 118px;
      padding-top: 23px;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #FFFFFF;
    }
  }

  .list {
    overflow: hidden;

    .item {
      width: 50%;
      float: left;
      padding-left: 50px;
      box-sizing: border-box;
      margin-bottom: 4px;
      margin-top: 40px;

      .twrap {
        margin-bottom: 12px;

        .t {
          font-family: PingFangSC-Semibold;
          font-size: 24px;
          color: #333333;
        }

        .err {
          color: red;
          font-family: PingFangSC-Semibold;
          font-size: 24px;
        }
      }

      .vWrap {
        position: relative;
        width: 498px;

        .selector {
          position: absolute;
          left: 0;
          top: 100%;

          div {
            width: 498px;
            height: 36px;
            background: #FFFFFF;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            outline: none;
            text-indent: 30px;
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #999999;
            line-height: 36px;

            &.sel {
              background: #07365e;
              color: #fff;
            }
          }
        }

        .v {
          width: 498px;
          height: 44px;
          background: #FFFFFF;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          outline: none;
          text-indent: 30px;
          font-family: PingFangSC-Regular;
          font-size: 20px;
          color: #000;
          line-height: 44px;

          &::-webkit-input-placeholder {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #999999;
            line-height: 44px;
          }
        }

        .default {
          color: #999;
        }

        label {
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #516380;
          position: absolute;
          right: 16px;
          top: 10px;
        }
      }
    }

    .require {
      .t::after {
        content: '*';
        display: inline-block;
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #FE4757;
        margin-left: 8px;
      }
    }
  }

  .submitBtn {
    width: 314px;
    height: 70px;
    margin: 46px auto 0;
    display: flex;
    border: 1px solid #979797;
    border-radius: 2px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    background: #2B2B2B url('~@/assets/image/submitBtn.png') no-repeat 266px center;
    background-size: 25px 25px;
  }
}
</style>